.p-top-mainvisual{
	&__link{
		display: block;
	}
	.swiper-pagination{
		padding: 10.5px 5px;
		@include mqPc {
			padding: 9px 5px;
		}
	}
}
.p-top-section__head{

}

.p-top-section{
	padding-top: 50px;
	padding-bottom: 55px;
	@include mqPc {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&:nth-of-type(n+2){
	}
	&__contents{
			margin: 25px auto 0 auto;
			padding: 0 5px;
			@include mqPc{
				padding: 0;
				margin: 29px auto 0 auto;
				max-width: calc(100% - 80px);
			}
			@include mqTb{
				max-width: calc(100% - 40px);
			}
			.c-productList__price+.c-productList__stuts{
				@include mqPc{
					margin-top: 8px;
				}
			}

		}
	&--newArrival{

	}

	&--feature{
		.p-card{
			padding: 0 15px;
			@include mqPc{
				padding: 0;
			}

		}
		.p-card__item{
			@include mqPc{
				overflow: hidden;
				&:hover{
					.p-card__text{
						transform: translateY(0%);
						transition: transform .3s ease-in;
					}
				}
			}
		}
		.p-card__text{
			font-size: 1.2rem;
			line-height: 1.58;
			padding: 10px;
			@include mqPc{
				padding: 30px;
				font-size: 1.6rem;
				line-height: 1.625;
				min-height: 52px;
				transform: translateY(100%);
				transition: transform .3s ease-out;
			}
		}
	}

	&--ranking{
		.p-productList__list{
			counter-reset: rankingNum;
		}

		.c-productList__item{
			margin-left: 0;
			margin-right: 0;
			@include mqPc{
				margin-left: 10px;
				margin-right: 10px;
				flex: 0 1 calc(20% - 20px);
				width: calc(20% - 20px);
				max-width: calc(20% - 20px);
			}
			&::before {
				position: absolute;
				font-family: $baseFontsFamilyEN;
				border-radius: 50%;
				padding: 5px;
				counter-increment: rankingNum;
				content: counter(rankingNum);
				color: #fff;
				z-index: 10;
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 1.4;
				left: 10px;
				top: 10px;
				background-color: #1C1C1C;
				@include mqPc{
					max-width: 20px;
					width: 20px;
					height: 20px;
					line-height: 1.8;
					padding: 0;
					font-size: 1.2rem;
				}
			}
			&:nth-of-type(1){
				&::before {
					background:#E1C200;
				}
			}
			&:nth-of-type(2){
				&::before {
					background: #B8B8B8;
				}
			}
			&:nth-of-type(3){
				&::before {
					background: #AF6500;
				}
			}
		}
	}

	&--news{
		.p-media__text{
			text-align: justify;
			height: calc((1.2rem * 1.66) * 2);
			overflow: hidden;
			position: relative;
			@include mqPc {
				height: calc((1.6rem * 1.625) * 2);
			}
			&::before,
			&::after{
				position: absolute;
				background-color: #fffffd;
			}
			&::before{
				right: 0;
				bottom: 0;
				content: "…";
			}
			&::after{
				width: 100%;
				height: 100%;
				content: "";
			}
		}
	}

	&--coordinate{
		.p-productList__list{
			padding-left: 0;
			padding-right: 0;
		}
	}

	&--viewedItems{
		padding-top: 5px;
		@include mqPc{
			padding-top: 0;
			padding-bottom: 50px;
		}
		.p-top-section__contents {
			overflow-x: hidden;
		}
		.p-productList__list{
			width: calc(100% + 10px);
			margin-left: -5px;
			@include mqPc{
				width: calc(100% + 20px);
				margin-left: -10px;
			}
		}
		.c-productList__item{
			flex: 0 0 calc(33.3% - 10px);
			width: calc(33.3% - 10px);
			max-width: calc(33.3% - 10px);
			margin-bottom: 0;
			@include mqPc {
				flex: 0 0 calc(10% - 20px);
				width: calc(10% - 20px);
				max-width: calc(10% - 20px);
			}
			&:nth-of-type(3n){
				margin-top: 0;
			}
			&:nth-of-type(n+4){
				display: none;
				@include mqPc {
					display: block;
					margin-top: 0;
				}
			}
			&:nth-of-type(n+11){
				@include mqPc {
					display: none;
				}
			}
		}
	}

	&--unique{
		padding-bottom: 80px;
		padding-top: 5px;
		@include mqPc{
			padding-top: 30px;

		}

		.p-top-section__contents{
			padding: 0;
		}
		.p-banner{
			&__list{
				@include mqPc{
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
				}
			}
			&__item{
				@include mqPc{
					flex: 0 1 43.5%;
				}
				&:nth-of-type(n+2){
					margin-top: 10px;
					@include mqPc{
						flex-wrap: wrap;
						flex: 1 0 29%;
						margin-top: 0;
						margin-left: 20px;
					}
				}
				&--long{
					@include mqPc{
						flex: 1 1 56.5%;
					}
				}
			}
			&__link{
				display: block;
			}
		}
	}

	.p-productList{
		&+.c-more{
			@include mqPc {
				margin-top: 40px;
			}
		}
	}

	&--feature{
		.p-top-section__contents{
			@include mqPc {
				padding-bottom: 40px;
			}
		}
	}

	&--feature,
	&--ranking{
		background-color: #F8F8F8;
	}
	&--ranking{
		.p-productList__list{
			padding: 0;
			width: 100%;
			.c-productList__item{
				@include mqPc {
					flex: 0 1 calc(20% - 20px);
					width: calc(20% - 20px);
					max-width: calc(20% - 20px);
				}
				&:nth-of-type(n+6){
					margin-right: 0;
				}
			}
		}

	}
	&--newArrival,
	&--feature,
	&--ranking,
	&--coordinate{
		.p-productList--spCarousel {
			padding: 0 15px;
			@include mqPc {
				padding: 0;
			}
			.p-productList__list{
				padding-right: 0;
				padding-left: 0;
				width: calc(100% + 10px);
				@include mqPc {
					width: calc(100% + 20px);
					margin-left: -10px;
				}
			}
			.c-productList__item{
				margin-left: 0;
				margin-right: 0;
				margin-top: 0;
				@include mqPc {
					margin-left: 10px;
					margin-right: 10px;
				}
				&:nth-of-type(n+6){
					margin-right: 0;
				}
			}
		}

	}

	&--newArrival,
	&--coordinate{
		.p-productList__list{
			.c-productList__item{
				&:nth-of-type(n+7){
					display: none;
				}
			}
		}
	}

	&--ranking{
		.p-productList__list{
			.c-productList__item{
				&:nth-of-type(n+6){
					display: none;
				}
			}
		}
	}
}


.p-productList{
	&__list{
		&--spCarousel{
			.c-productList__item{
				&:nth-of-type(n+7){
					display: none;
				}
			}
		}
	}
}

.p-top-link__list{
	border-top: 1px solid #ddd;
	@include mqPc {
		display: none;
	}
	.c-top-link{
		font-size: 1.6rem;
		line-height: 1.25;
		border-bottom: 1px solid #ddd;
		padding: 17px 40px 17px 20px;
		display: block;
		position: relative;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

